@use "colors.scss" as *;

$sans-serif: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui,
helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;

$serif: georgia, Iowan Old Style, Apple Garamond, Baskerville, Times New Roman, Droid
Serif, Times, Source Serif Pro, serif, Apple Color Emoji, Segoe UI Emoji, Segoe
UI Symbol;

$book: et-book,Palatino,Palatino Linotype,Palatino LT STD,Book Antiqua,Georgia,serif;

#search {
  --pagefind-ui-primary: #eeeeee;
  --pagefind-ui-text: $color-gray-2;
  --pagefind-ui-background: $color-gray-2;
  --pagefind-ui-border: #152028;
  --pagefind-ui-tag: #152028;
}

html {
  margin: auto;
  padding-top: 2em;
  line-height: 1.4;
  font-family: $sans-serif;
  font-size: calc(1em * 1.05);
  background: white;
  color: $color-gray-5;
  max-width: 80ch;
}

div.container {
  margin-right: 22%;
}

aside, .aside {
  display: inline;
  float: right;
  position: relative;
  width: 20vw;
  margin-right: -22vw;
  font-size: calc(1em * 0.7);
}

@media (max-width: 768px) {
  div.container {
    margin: 1rem;
  }
  aside, .aside {
    display: block;
    float: none;
    margin: 5% 10% 5% 10%;
    width: 80%;
    font-size: calc(1em * 0.9);
  }
}

h1,
h2,
h3,
h4 {
  margin: 1em 0 0;
}

p {
  margin-top: 1em;
}

// link
a {
  color: $color-blue-3;
  text-decoration: none;
}

a:hover {
  font-style: italic;
}

hr {
  height: 1px;
  border-width: 0px;
  margin: 2rem 0rem;
  color: $color-red-3;
  background-color: lighten($color-gray-3, 15%);
}

code,
pre {
  font-family: Menlo, Consolas, Monaco, Liberation Mono, Lucida Console, monospace;
  font-size: calc(1em * 0.9);
}

footer {
  //padding-top: 3em;
  //text-align: center;
}

.lineno {
  user-select: none;
}

.red {
  color: $color-red-4;
}

.done {
  background: $color-emerald-1;
}

blockquote {
  margin: 1rem 0.5rem 1rem;
  padding: 0.1rem 0.7rem;
  background: $color-blue-1;
}

blockquote.red {
  color: $color-red-5;
  background: $color-red-2;
}


.footnotes {
  margin: 0;
  line-height: 50%;
  font-size: calc(1em * 0.85);
}

.muted {
  color: lighten($color-gray-3, 15%);
}

.title {
  color: $color-gray-5;
}

ul {
  padding-left: 1rem;
}

ul ul {
  padding-left: 1.3rem;
}

ol {
  padding-left: 1rem;
}

ol ol {
  padding-left: 1.3rem;
}

figure {
  margin: 0;
}

// .language-plaintext {
//   background: lighten($color-amber-2, 5%);
// }

table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
  border-color: $color-gray-3;
  margin: auto;
}

td {
  padding: 0.5rem;
}

.bottom-element {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px;
}
